@font-face {
    font-family: "Mont Heavy";
    src: url("../fonts/Mont/Mont Heavy/Mont Heavy.otf") format("opentype");
}

@font-face {
    font-family: "Mont Light";
    src: url("../fonts/Mont/Mont Light/Mont Light.otf") format("opentype");
}

@font-face {
    font-family: "NGMI";
    src: url("../fonts/ka1.ttf") format("truetype");
}
