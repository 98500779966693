body {
    font-family: "Mont Light", sans-serif;
    background: #37393f;
    color: #fff;
}

body.no-scroll {
    overflow: hidden;
    padding-right: 10px;
}

/* width */
::-webkit-scrollbar {
    width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #000;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #eaad54;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #a87020;
}

img {
    max-width: 100%;
}
