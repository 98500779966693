/* TOP BAR */

.ngmi-top-bar {
    position: absolute;
    top: 0;
    width: 100%;
    padding: 3.875rem 5rem 0;
}

@media screen and (max-width: 1366px) {
    .ngmi-top-bar {
        padding: 2rem 2rem 0;
    }
}

@media screen and (max-width: 768px) {
    .ngmi-top-bar {
        padding: 2rem 1rem 0;
    }
}

.ngmi-logo {
    width: 11.625rem;
    top: 3.875rem;
}

.ngmi-hamburger-menu {
    position: relative;
    background: none;
    border: 0;
    overflow: hidden;
    z-index: 999;
}

.ngmi-hamburger-menu__bar {
    display: block;
    width: 3.125rem;
    height: 0.5rem;
    background: #eaad54;
    margin: 0.5rem 0;
    border-radius: 3px;
    transition: all 0.2s ease-out;
}

.ngmi-hamburger-menu.active .ngmi-hamburger-menu__bar:first-child {
    transform: rotate(45deg) translateY(0.7rem);
}

.ngmi-hamburger-menu.active .ngmi-hamburger-menu__bar:last-child {
    transform: rotate(-45deg) translateY(-0.7rem);
}

/* DRAWER MENU */

.ngmi-navigation {
    position: fixed;
    z-index: 998;
    right: -20rem;
    top: 0;
    width: 20rem;
    max-width: 100%;
    height: 100%;
    background: #37393f;
    transition: all 0.2s ease-out;
}

.ngmi-navigation.active {
    right: 0;
}

.ngmi-menu {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.ngmi-menu__item {
    margin-bottom: 1.25rem;
}

.ngmi-menu__item a {
    font-family: "Mont Heavy", sans-serif;
    font-size: 2rem;
    color: #eaad54;
    text-decoration: none;
}

.ngmi-menu__item a:hover {
    text-decoration: underline;
}

/* HOME SECTIONS */

.ngmi-section {
    padding: 0 1rem;
}

.ngmi-section__heading {
    font-family: "Mont Heavy", sans-serif;
    font-size: 3.75rem;
    line-height: 1.17;
}

@media screen and (max-width: 768px) {
    .ngmi-section__heading {
        font-size: 2.5rem;
    }
}

/* MINT SECTION */

.ngmi-mint {
    min-height: 100vh;
    background: url('../images/header-pixels.png') no-repeat center center;
    background-size: cover;
    padding-top: 9.5rem;
    padding-bottom: 3rem;
}

.mint-container {
    width: 45.313rem;
    max-width: 100%;
}

.ngmi-mint__subheading {
    font-size: 1.875rem;
    line-height: 1.33;
    margin-bottom: 1rem;
}

.ngmi-mint__heading {
    font-family: "Mont Heavy", sans-serif;
    font-size: 2.5rem;
    line-height: 1.25;
    margin-bottom: 7rem;
}

@media screen and (max-width: 640px) {
    .ngmi-mint__heading {
        margin-bottom: 6rem;
    }
}

.ngmi-login {
}

.ngmi-login__label {
    max-width: 100%;
}

.ngmi-login__label__text {
    display: block;
    font-family: "NGMI", sans-serif;
    font-size: 2.5rem;
    color: #fff;
    margin-bottom: 2.5rem;
}

@media screen and (max-width: 640px) {
    .ngmi-login__label__text {
        font-size: 1.875rem;
    }
}

.ngmi-login__input {
    width: 30.563rem;
    max-width: 100%;
    height: 5.625rem;
    font-size: 2.5rem;
    text-align: center;
    margin-bottom: 1.25rem;
}

.monolif-typing {
    opacity: 0;
    animation: monolifTypingEntranceAnimation 0.5s 1s forwards;
}

@keyframes monolifTypingEntranceAnimation {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.monolif-typing__dots {
    position: relative;
    margin: 0 1.2rem 0 0;
}

.monolif-typing__middle-dot,
.monolif-typing__dots:before,
.monolif-typing__dots:after {
    width: 0.438rem;
    height: 0.438rem;
    border-radius: 0.438rem;
    background: #d8d8d8;
    opacity: 0.1;
}

.monolif-typing__dots:before {
    content: '';
    position: absolute;
    left: -0.6rem;
    animation: monolifDotAnimation 1.1s 0s infinite;
}

.monolif-typing__middle-dot {
    animation: monolifDotAnimation 1.1s 0.2s infinite;
}

.monolif-typing__dots:after {
    content: '';
    position: absolute;
    right: -0.6rem;
    animation: monolifDotAnimation 1.1s 0.3s infinite;
}

.mintbox-container {
}

.mint-button {

}

.wallet-adapter-button-trigger {
    position: relative;
    background: #a9680b;
    border: none;
    padding: 0;
    cursor: pointer;
    outline-offset: 4px;
    font-family: "Mont Heavy", sans-serif;
}

.wallet-adapter-button__front {
    display: block;
    padding: 12px 42px;
    font-size: 1.25rem;
    background: #eaad54;
    transform: translateY(-6px);
}

.wallet-adapter-button-trigger:active .wallet-adapter-button__front {
    transform: translateY(-2px);
}

@keyframes monolifDotAnimation {
    0% {
        opacity: 0.1;
        transform: scale(0.5);
    }

    50% {
        opacity: 1;
        transform: scale(1);
    }

    100% {
        opacity: 0.1;
        transform: scale(0.5);
    }
}

.wallet-adapter-button-start-icon {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateY(-4rem) translateX(-50%);
}

.monolif-typing__text {
    font-size: 1.25rem;
}

.monolif-typing__text__heavy {
    font-family: "Mont Heavy", sans-serif;
}

/* WALLETS MODAL */

.wallet-adapter-modal {
    position: fixed;
    z-index: 1001;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    transition: all 0.2s ease-out;
    visibility: hidden;
    opacity: 0;
}

.wallet-adapter-modal-fade-in {
    visibility: visible;
    opacity: 1;
}

.wallet-adapter-modal-container {
    background: #000;
    position: relative;
    z-index: 2;
}

.wallet-adapter-modal-overlay {
    position: absolute;
    z-index: 1;
    top: 0;
    lefT: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
}

.wallet-adapter-modal-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 20rem;
    max-width: 100%;
}

.wallet-adapter-modal-title {
    font-size: 1.5rem;
    padding: 1rem;
    background: #3f51b5;
    margin: 0;
}

.wallet-adapter-modal-button-close {
    position: absolute;
    top: 1rem;
    right: 1rem;
    background: none;
    border: 0;
}

.wallet-adapter-modal-button-close svg {
}

.wallet-adapter-modal-button-close svg path {
    fill: #fff;
}

.wallet-adapter-modal-list {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.wallet-adapter-modal-list li {
    border-bottom: 1px solid rgba(255, 255, 255, 0.25);
}

.wallet-adapter-modal-list .wallet-adapter-button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    background: #000;
    border: 0;
    padding: 0.7rem 1rem;
    color: #fff;
    font-size: 1.25rem;
    transition: all 0.2s ease-out;
}

.wallet-adapter-modal-list .wallet-adapter-button:hover {
    background: #333;
}

.wallet-adapter-modal-list .wallet-adapter-button-end-icon {
    width: 2.5rem;
    height: 2.5rem;
}

.wallet-adapter-modal-list .wallet-adapter-button-end-icon img {
    width: 100%;
}

.wallet-adapter-collapse {
}

.wallet-adapter-modal-collapse-button {
    background: #000;
    border: 0;
    padding: 1rem;
    color: #fff;
    transition: all 0.2s ease-out;
    display: flex;
    justify-content: space-between;
}

.wallet-adapter-modal-collapse-button:hover {
    background: #333;
}

.wallet-adapter-button-end-icon path {
    fill: #fff;
}

.wallet-adapter-modal-collapse-button-active .wallet-adapter-button-end-icon {
    transform: rotate(180deg);
}

.mint-button {
    background: #a9680b;
    border: none;
    padding: 0;
    cursor: pointer;
    outline-offset: 4px;
    font-family: "Mont Heavy", sans-serif;
}

.mint-button__front {
    display: block;
    padding: 12px 42px;
    font-size: 1.25rem;
    background: #eaad54;
    transform: translateY(-6px);
}

.mint-button:active .mint-button__front {
    transform: translateY(-2px);
}

.scroll-down-button {
    display: inline-block;
    margin-top: 9rem;
    animation: scrollButtonAnimation 1.5s infinite;
}

.scroll-down-button__icon {

}

@keyframes scrollButtonAnimation {
    0% {
        transform: translateY(0);
    }

    15% {
        transform: translateY(10px);
    }

    30% {
        transform: translateY(0);
    }

    45% {
        transform: translateY(10px);
    }

    60% {
        transform: translateY(0);
    }

    100% {
        transform: translateY(0);
    }
}

/* NFT CLUB SECTION */

.ngmi-section--nft-club {
    padding-top: 3rem;
    padding-bottom: 27rem;
    background: url('../images/pixels-1.png') no-repeat center bottom;
    background-size: 100%;
}

@media screen and (max-width: 640px) {
    .ngmi-section--nft-club {
        background-size: auto;
    }
}

.ngmi-nft-list {
    list-style-type: none;
    margin: 0;
    padding: 0;

}

@media screen and (max-width: 640px) {
    .ngmi-nft-list {
        overflow: auto;
        padding: 0 0 2rem 0;
    }
}

.ngmi-nft-list__item {
    padding: 0 1.125rem;
}

/* ABOUT SECTION */

.ngmi-section--about {
    padding: 0;
}

.ngmi-about__inner {
    padding: 8.375rem 1rem 0;
    background: #4a443f;
}

.ngmi-about__bottom {
    width: 100%;
    height: 30rem;
    background-image: url('../images/pixels-2.png');
    background-repeat: no-repeat;
    background-position: center top;
    background-size: 100%;
}

@media screen and (max-width: 640px) {
    .ngmi-about__bottom {
        background-size: auto;
    }
}

.ngmi-about__meta-heading {
    position: relative;
    font-family: "Mont Light", sans-serif;
    font-size: 4.375rem;
    font-weight: 300;
    margin-bottom: 9.375rem;
}

@media screen and (max-width: 640px) {
    .ngmi-about__meta-heading {
        font-size: 1.875rem;
    }
}

.ngmi-about__meta-heading:before {
    content: '';
    display: block;
    background: url('../images/pixels-symbol-1.svg') no-repeat center center;
    background-size: cover;
    width: 1.5rem;
    height: 1.875rem;
    margin-right: 2.5rem;
}

.ngmi-about__meta-heading:after {
    content: '';
    display: block;
    background: url('../images/pixels-symbol-2.svg') no-repeat center center;
    background-size: cover;
    width: 1.5rem;
    height: 1.875rem;
    margin-left: 2.5rem;
}

.ngmi-about__fact {
    font-size: 2.5rem;
    line-height: 1.5;
}

.ngmi-about__fact:last-child {
    margin-bottom: 0;
}

/* FAQ SECTION */

.ngmi-section--faq {
    padding-top: 3rem;
    padding-bottom: 10rem;
}

.ngmi-faq-box {
    width: 76.188rem;
    max-width: 100%;
    margin-bottom: 1.875rem;
    border-left: 0.5rem solid #eaad54;
    background: #4a443f;
    padding: 0.25rem 3.875rem 0 3.563rem;
}

@media screen and (max-width: 640px) {
    .ngmi-faq-box {
        padding: 2rem 1.25rem;
    }
}

.ngmi-faq-box__question {
    font-family: "Mont Heavy", sans-serif;
    font-size: 1.875rem;
    line-height: 1.33;
    margin-bottom: 1.88rem;
}

@media screen and (max-width: 640px) {
    .ngmi-faq-box__question {
        font-size: 1.25rem;
    }
}

.ngmi-faq-box__answer {
    font-size: 1.5rem;
    line-height: 1.25;
    margin-bottom: 0;
}

@media screen and (max-width: 640px) {
    .ngmi-faq-box__answer {
        font-size: 1rem;
    }
}

.ngmi-faq-box__reactions {

}

.ngmi-faq-box__reaction {
    border: 0;
    padding: .125rem .375rem;
    border-radius: 0.5rem;
    background-color: #37393f;
    font-size: 0.875rem;
    color: #fff;
    margin: 0 0.25rem 0.25rem 0;
}

@media screen and (max-width: 640px) {
    .ngmi-faq-box__reaction {
        font-size: 0.9rem;
        margin-right: 0.5rem;
    }
}

.ngmi-faq-box__reaction__icon {
    margin-right: .375rem;
}

.ngmi-faq-box__reaction__count {
}

/* FOOTER */

.ngmi-footer {
    background: #eaad54;
    color: #37393f;
    padding: 3.375rem 1rem 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.ngmi-twitter {
    margin: 3rem 0 2rem 0;
}

.ngmi-copyright-text {
    font-family: "Mont Heavy", sans-serif;
    font-size: 1.25rem;
    text-align: center;
}
